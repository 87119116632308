import { format, formatISO, startOfTomorrow } from 'date-fns'
import { dateFormat, dateTimeFullFormat, dateTimeShortFormat } from '@constants/configuration'

export const now = (): string => formatISO(new Date())
export const formatDate = (d: Date): string => format(d, dateFormat)
export const tomorrowFormatted = formatDate(startOfTomorrow())
export const formatDateShort = (d: Date): string => format(d, dateTimeShortFormat)
export const formatDateFull =
  (locale: Locale) =>
  (d: Date | string): string =>
    format(new Date(d), dateTimeFullFormat, { locale })
